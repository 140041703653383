<template>
  <div>
    <!-- Display of selected fields  -->
    <!-- active-class="primary text" -->
    <v-chip-group column v-if="anySelectedField">
      <v-chip outlined v-for="field in selectedFields" :key="field.id">
        {{ fieldName(field) }}
      </v-chip>
    </v-chip-group>

    <v-divider v-if="anySelectedField"></v-divider>

    <!-- Filter Fields  -->
    <v-sheet>
      <v-text-field
        v-model="treeSearch"
        :prepend-icon="iconSearch"
        label="Find Field"
        flat
        hide-details
        clearable
      ></v-text-field>
    </v-sheet>

    <v-divider></v-divider>

    <!-- Fields tree view -->
    <v-treeview
      v-bind="$attrs"
      v-on="$listeners"
      :item-key="treeItemKey"
      :items="fields"
      :search="treeSearch"
      :filter="treeFilter"
      :open.sync="treeOpen"
      @input="onInput"
      selectable
    >
    </v-treeview>
  </div>
</template>

<script>
import { iconAdd, iconSearch } from "@/design/icon/iconConst";
import { fieldName } from "@/model/field/fieldModel";

import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";

export default {
  name: "FieldBrowser",
  mixins: [reactiveMixin],
  inheritAttrs: false,
  data() {
    return {
      // Icons
      iconSearch: iconSearch,
      iconAdd: iconAdd,

      // Tree
      treeItemKey: fieldName.id,
      treeOpen: [],
      treeSearch: null,
      treeCaseSensitive: false,
      treeSelectedItems: []
    };
  },
  props: {
    fields: {
      required: true,
      default: () => []
    }
  },
  computed: {
    treeFilter() {
      return this.treeCaseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    countTreeSelectedItems() {
      return this.treeSelectedItems?.length ?? 0;
    },
    countSelectedFields() {
      return this.selectedFields?.length ?? 0;
    },
    anySelectedField() {
      return this.countSelectedFields > 0;
    },
    selectedFields() {
      const fields = [];
      this.treeSelectedItems.forEach(id => {
        this.fields.forEach(fl => {
          const field = fl.children?.find(cf => cf.id === id);
          if (field) {
            fields.push(field);
          }
        });
      });
      return fields;
    }
  },
  methods: {
    onInput(selectedItems) {
      this.treeSelectedItems = selectedItems ?? [];
    },
    fieldName(field) {
      return field?.name ?? `Field (${field?.id ?? -1})`;
    }
  }
};
</script>
